import React, { Fragment, useEffect, useState, memo, useRef } from "react";
import moment, { Moment } from "moment";
import { Divider } from "antd";

import { WorkHours } from "shared";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { CkModal } from "../../../CkUI";

import "./RescheduleModal.css";

interface IProps {
  loadingReschedule: boolean;
  startDateTime: any;
  showNewAppointmentDate: boolean;
  setShowNewAppointmentDate: Function;
  getClosedDays: Function;
  newAppointmentDate: { date: string; time: string };
  setNewAppointmentDate: Function;
  getMonthByNumber: Function;
  appointmentHourList: any[];
  rescheduleAppointment: Function;
  getWorkingHoursByDay: Function;
  hours: WorkHours[];
}

interface IDay {
  day: {
    day: string;
    dayNum: string | number;
    date: string;
    isPastDate: boolean;
  };
  isClosed: boolean;
  currentDate: string;
  dateSelected: string | undefined;
  setDateSelected: Function;
  setHourSelected: Function;
  updateDate: Function;
}

interface IDateTitle {
  weekText: string;
  handlePreviousWeek: Function;
  handleNexWeek: Function;
}

interface IDayShift {
  loadingReschedule: boolean;
  dayShift: boolean;
  setDayShift: Function;
}

interface IHour {
  loadingReschedule: boolean;
  hour: IHoursOfDay;
  hourSelected: string | undefined;
  setHourSelected: Function;
  updateTime: Function;
}

interface IHoursOfDay {
  currentFormat: string;
  momentFormat: string;
}

interface HourItem {
  currentFormat: string;
  momentFormat: string;
}

function deleteLastItem(array: string[]): string[] {
  if (array.length > 0) {
    array.pop();
  }
  return array;
}

function isCloseDay(arr: string[], str: string): boolean {
  return arr.includes(str);
}

function adjustOpenTime(time: any) {
  const originalMoment = moment(time, "HH:mm:ss.SSSZ");
  const adjustedMoment = originalMoment.subtract(0, "hours");
  return adjustedMoment.format("HH:mm:ss.SSS");
}

function adjustCloseTime(time: any) {
  const originalMoment = moment(time, "HH:mm:ss.SSSZ");
  const adjustedMoment = originalMoment.subtract(0, "hours");
  return adjustedMoment.format("HH:mm:ss.SSS");
}

function getAvailableHours(
  date: Moment,
  schedule: WorkHours[],
  week: {
    weekNumber: number;
    days: {
      day: string;
      dayNum: string | number;
      date: string;
      isPastDate: boolean;
    }[];
  }
): string[] {
  if (week) {
    const weekFilter = week.days.filter((wk) => {
      return wk.date == moment(date).add(1, "day").format("YYYY-MM-DD");
    });

    let tempDayCode = 0;

    if (weekFilter && weekFilter.length > 0) {
      switch (weekFilter[0].day) {
        case "lu":
          tempDayCode = 1;
          break;
        case "ma":
          tempDayCode = 2;
          break;
        case "mi":
          tempDayCode = 3;
          break;
        case "ju":
          tempDayCode = 4;
          break;
        case "vi":
          tempDayCode = 5;
          break;
        case "sá":
          tempDayCode = 6;
          break;
        case "do":
          tempDayCode = 7;
          break;
        default:
          break;
      }
    }

    const todaySchedule = schedule.find((item) => {
      return item.dayCode == tempDayCode;
    });

    if (!todaySchedule || !todaySchedule.isOpen) {
      return [];
    }

    const openMoment = moment(todaySchedule.openTime, "HH:mm:ss.SSS");
    const closeMoment = moment(todaySchedule.closeTime, "HH:mm:ss.SSS");

    const availableHours: string[] = [];

    let currentTime = openMoment.clone();
    // there should not be any appointment for the last hour of a workshop
    const maxAppointmentTime = closeMoment
      .subtract(60, "minutes")
      .add(1, "minutes");

    while (currentTime.isBefore(maxAppointmentTime)) {
      availableHours.push(currentTime.format("HH:mm"));
      currentTime.add(30, "minutes");
    }

    return availableHours;
  } else {
    return [];
  }
}

const getAMorPM = (startDateTime: any) => {
  const currentDate = new Date(startDateTime);
  const currentHour = currentDate.getHours();

  if (currentHour < 12) {
    return true;
  } else {
    return false;
  }
};

function filterAndFormatHours(isAM: boolean, hours: string[]): HourItem[] {
  const tempHours: string[] = [...hours];

  const filteredHours = tempHours.filter((hour) => {
    const hourMoment = moment(hour, "HH:mm");
    return isAM
      ? hourMoment.isBefore(moment("12:00", "HH:mm"))
      : hourMoment.isAfter(moment("12:00", "HH:mm"));
  });

  const formattedHours: HourItem[] = filteredHours.map((hour) => ({
    currentFormat: hour,
    momentFormat: moment(hour, "HH:mm").format("HH:mm:ss-06:00"),
  }));

  return formattedHours;
}

const getCurrentDate = (): string => {
  return moment().format("YYYY-MM-DD");
};

const getCurrentWeekNumber = (): number => {
  return moment().week();
};

const getWeekText = (week: {
  days: { day: string; date: string }[];
}): string => {
  try {
    // Get the date of the first and last day of the week
    const startDate = moment(week.days[0].date);
    const endDate = moment(week.days[6].date);

    // Get the month name and day for both dates
    const startMonth = startDate.format("MMM");
    const endMonth = endDate.format("MMM");
    const startDay = startDate.format("DD");
    const endDay = endDate.format("DD");

    // Compose the text in the desired format
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  } catch (err) {
    return "Error";
  }
};

const GrayDivider = memo(() => {
  return (
    <>
      {/* @ts-ignore */}
      <Divider
        style={{
          borderColor: "rgba(204, 204, 204, 1)",
          margin: "20px 0px 0px 0px",
        }}
      />
    </>
  );
});

const DateTitle: React.FC<IDateTitle> = memo(
  ({ weekText, handlePreviousWeek, handleNexWeek }) => {
    return (
      <div className="row-container">
        {/* @ts-ignore */}
        <LeftOutlined onClick={() => handlePreviousWeek()} />
        <p className="date-title">{weekText}</p>
        {/* @ts-ignore */}
        <RightOutlined onClick={() => handleNexWeek()} />
      </div>
    );
  }
);

const DayCard: React.FC<IDay> = memo(
  ({
    day,
    isClosed,
    currentDate,
    dateSelected,
    setDateSelected,
    setHourSelected,
    updateDate,
  }) => {
    return (
      <div
        className={[
          "week-card",
          " ",
          ...(dateSelected == day.date ? ["selected"] : [""]),
          ...(day.isPastDate || isClosed ? ["disabled"] : [""]),
        ].join("")}
        onClick={() => {
          if (!day.isPastDate && !isClosed) {
            setDateSelected(day.date);
            setHourSelected(undefined);
            updateDate(moment(day.date));
          }
        }}
      >
        <p className="str">{day.day}</p>
        <p
          className={[
            "num",
            " ",
            ...(currentDate == day.date && dateSelected != day.date
              ? ["today"]
              : [""]),
          ].join("")}
        >
          {day.dayNum}
        </p>
      </div>
    );
  }
);

const DayShift: React.FC<IDayShift> = memo(
  ({ loadingReschedule, dayShift, setDayShift }) => {
    return (
      <div
        className={[
          "day-shift",
          " ",
          ...(loadingReschedule ? ["disabled "] : [""]),
        ].join("")}
        onClick={() => {
          if (!loadingReschedule) {
            setDayShift(!dayShift);
          }
        }}
      >
        <div
          className={[
            "am",
            " ",
            ...(dayShift ? ["orange-background "] : [""]),
            ...(loadingReschedule ? ["disabled "] : [""]),
          ].join("")}
        >
          <p>AM</p>
        </div>
        <div
          className={[
            "pm",
            " ",
            ...(!dayShift ? ["orange-background "] : [""]),
            ...(loadingReschedule ? ["disabled "] : [""]),
          ].join("")}
        >
          <p>PM</p>
        </div>
      </div>
    );
  }
);

const HourCard: React.FC<IHour> = memo(
  ({ loadingReschedule, hour, hourSelected, setHourSelected, updateTime }) => {
    return (
      <div
        className={[
          "hour-card",
          " ",
          ...(hourSelected == hour.momentFormat ? ["selected"] : [""]),
          ...(loadingReschedule ? ["disabled"] : [""]),
        ].join("")}
        onClick={() => {
          if (!loadingReschedule) {
            setHourSelected(hour.momentFormat);
            updateTime(hour.currentFormat);
          }
        }}
      >
        <p className="hour-info">{hour.currentFormat}</p>
      </div>
    );
  }
);

export const RescheduleModal: React.FC<IProps> = ({
  loadingReschedule,
  startDateTime,
  showNewAppointmentDate,
  setShowNewAppointmentDate,
  getClosedDays,
  newAppointmentDate,
  setNewAppointmentDate,
  getMonthByNumber,
  appointmentHourList,
  rescheduleAppointment,
  getWorkingHoursByDay,
  hours,
}) => {
  const weekListSizeRef = useRef<HTMLDivElement | null>(null);

  const initialHour = moment(startDateTime).second(0).format("HH:mm:ssZ");
  const initialDate = moment(startDateTime).format("YYYY-MM-DD");

  const currentDate = getCurrentDate();
  const currentWeekNumber = getCurrentWeekNumber() - 1;
  const [closedDays, setClosedDays] = useState<string[]>([]);

  const [dayShift, setDayShift] = useState<boolean>(getAMorPM(startDateTime));

  // const amHours = generateHoursOfDay(dayShift);
  const [amHours, setAmHours] = useState<IHoursOfDay[] | undefined>(undefined);
  const [availableHours, setAvailableHours] = useState<string[] | undefined>(
    undefined
  );
  const [hourSelected, setHourSelected] = useState<string | undefined>(
    moment(startDateTime).second(0).format("HH:mm:ssZ")
  );

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [weekSelected, setWeekSelected] = useState<number>(currentWeekNumber);
  const [dateSelected, setDateSelected] = useState<string | undefined>(
    moment(startDateTime).format("YYYY-MM-DD")
  );

  const [weeks, setWeeks] = useState<
    {
      weekNumber: number;
      days: {
        day: string;
        dayNum: string | number;
        date: string;
        isPastDate: boolean;
      }[];
    }[]
  >([]);

  const weekText =
    weeks && weeks[weekSelected] ? getWeekText(weeks[weekSelected]) : "";

  useEffect(() => {
    getWeeks(year);
  }, []);

  useEffect(() => {
    if (availableHours) {
      const filteredHours = filterAndFormatHours(dayShift, availableHours);
      setAmHours([...filteredHours]);
    }
  }, [dayShift, availableHours]);

  useEffect(() => {
    const closeDays = getClosedDays();
    let days: string[] = [];
    if (closeDays) {
      closeDays.map((day: number) => {
        switch (day) {
          case 0:
            days.push("do");
            break;
          case 1:
            days.push("lu");
            break;
          case 2:
            days.push("ma");
            break;
          case 3:
            days.push("mi");
            break;
          case 4:
            days.push("ju");
            break;
          case 5:
            days.push("vi");
            break;
          case 6:
            days.push("sá");
            break;
          default:
            break;
        }
      });
    }
    setClosedDays([...days]);
  }, []);

  useEffect(() => {
    if (dateSelected && weekSelected !== undefined && weeks) {
      const dateMoment = moment(new Date(dateSelected));
      const adjustedHours = hours.map((item) => ({
        ...item,
        openTime: adjustOpenTime(item.openTime),
        closeTime: adjustCloseTime(item.closeTime),
      }));
      const week = weeks[weekSelected];
      const schedule = adjustedHours;
      const tempAvailableHours = getAvailableHours(dateMoment, schedule, week);
      setAvailableHours([...tempAvailableHours]);
    }
  }, [dateSelected, weeks]);

  const scrollToStart = () => {
    if (weekListSizeRef.current) {
      weekListSizeRef.current.scrollLeft = 0;
    }
  };

  const scrollToEnd = () => {
    if (weekListSizeRef.current) {
      weekListSizeRef.current.scrollLeft = weekListSizeRef.current.scrollWidth;
    }
  };

  const getWeeks = (localYear: number) => {
    const generateWeeks = (currentYear: number) => {
      const generatedWeeks: {
        weekNumber: number;
        days: {
          day: string;
          dayNum: string | number;
          date: string;
          isPastDate: boolean;
        }[];
      }[] = [];
      const currentDate = moment();

      let startDate = moment().year(currentYear).startOf("year");

      while (startDate.year() === currentYear) {
        const currentWeek: {
          day: string;
          dayNum: string | number;
          date: string;
          isPastDate: boolean;
        }[] = [];

        // We adjust the start of the week to Monday
        if (startDate.day() !== 1) {
          startDate.startOf("week").add(1, "day");
        }

        for (let i = 0; i < 7; i++) {
          const date = startDate.format("YYYY-MM-DD");
          const isPastDate = startDate.isBefore(currentDate, "day");

          currentWeek.push({
            day: startDate.format("dd"),
            dayNum: startDate.format("D"),
            date,
            isPastDate,
          });
          startDate.add(1, "day");
        }

        const weekNumber = startDate.week();
        generatedWeeks.push({ weekNumber, days: currentWeek });
      }

      return generatedWeeks;
    };

    const generateWeeksForCurrentYear = () => {
      const generatedWeeks = generateWeeks(localYear);
      setWeeks(generatedWeeks);
      return generatedWeeks;
    };

    return generateWeeksForCurrentYear();
  };

  const handleNextYear = () => {
    setYear((prevYear) => prevYear + 1);
  };

  const handlePreviousYear = () => {
    setYear((prevYear) => prevYear - 1);
  };

  const handleNexWeek = async () => {
    if (weekSelected == weeks.length - 1) {
      const localWeeks = await getWeeks(year + 1);
      handleNextYear();
      setWeekSelected(0);
    } else {
      setWeekSelected(weekSelected + 1);
    }
  };

  const handlePreviousWeek = async () => {
    if (weekSelected == 0) {
      const localWeeks = await getWeeks(year - 1);
      handlePreviousYear();
      setWeekSelected(localWeeks.length - 1);
    } else {
      setWeekSelected(weekSelected - 1);
    }
  };

  const updateDate = (dateProp: any) => {
    const date = moment(dateProp);
    const info = { ...newAppointmentDate, date };
    setNewAppointmentDate(info);
  };

  const updateTime = (time: string) => {
    const info = {
      ...newAppointmentDate,
      time: time,
    };
    setNewAppointmentDate(info);
  };

  const isInitial = () => {
    if (dateSelected == initialDate && hourSelected == initialHour) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      {/* @ts-ignore */}
      <CkModal
        className="reschedule-modal"
        open={showNewAppointmentDate}
        onCancel={() => {
          if (!loadingReschedule) {
            setShowNewAppointmentDate(false);
          }
        }}
        zIndex={5000}
        title="Selecciona una nueva fecha y hora"
        primaryAction={{
          label: "Confirmar cambio",
          onClick: () => rescheduleAppointment(),
          loading: loadingReschedule,
          disabled: !(dateSelected && hourSelected) || isInitial(),
        }}
        actionButtonsDirection={"column"}
      >
        <div className="reschedule-container">
          <div className="week-container">
            <DateTitle
              weekText={weekText}
              handlePreviousWeek={handlePreviousWeek}
              handleNexWeek={handleNexWeek}
            />

            <div className="carrousel-container">
              {/* @ts-ignore */}
              <LeftOutlined className="mob" onClick={() => scrollToStart()} />
              <div
                className="week-list-size"
                style={{
                  scrollBehavior: "smooth",
                }}
                ref={weekListSizeRef}
              >
                <div className="week-list">
                  {weeks &&
                    weeks[weekSelected] &&
                    weeks[weekSelected].days.map((day, index) => (
                      <DayCard
                        day={day}
                        isClosed={
                          isCloseDay(closedDays, day.day) || loadingReschedule
                        }
                        currentDate={currentDate}
                        dateSelected={dateSelected}
                        setDateSelected={setDateSelected}
                        setHourSelected={setHourSelected}
                        updateDate={updateDate}
                      />
                    ))}
                </div>
              </div>
              {/* @ts-ignore */}
              <RightOutlined className="mob" onClick={() => scrollToEnd()} />
            </div>

            <GrayDivider />

            <DayShift
              loadingReschedule={loadingReschedule}
              dayShift={dayShift}
              setDayShift={setDayShift}
            />

            <div className="hour-grid wrap">
              {amHours &&
                amHours.map((hour, index) => (
                  <HourCard
                    loadingReschedule={loadingReschedule}
                    hour={hour}
                    hourSelected={hourSelected}
                    setHourSelected={setHourSelected}
                    updateTime={updateTime}
                  />
                ))}
            </div>

            <p className="warning-message">
              {isInitial() && "La fecha y hora no pueden ser la misma"}
              {amHours &&
                amHours.length == 0 &&
                "No hay horas disponibles para esta jornada"}
            </p>
          </div>
        </div>
      </CkModal>
    </Fragment>
  );
};
