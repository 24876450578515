import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Layout, Avatar, Popover, Button } from "antd";
import { UserOutlined, CaretDownOutlined } from "@ant-design/icons";

import Logo from "../../../assets/Images/Logo/LogoCK.png";

import { CurrentUser } from "../../../context/UserContext";
import { getSasToken } from "../../../stores/selectors";
import { serviceSAS } from "shared";

import "./style.css";

const { Header } = Layout;

interface IProps {
  logout: Function;
}

const MemoizedConfigNavbar: React.FC<IProps> = ({ logout }) => {
  const dispatch = useDispatch();

  const user = React.useContext(CurrentUser);

  const sasToken: string = useSelector(getSasToken);

  useEffect(() => {
    getSasTokenAzure();
  }, []);

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  const content = () => {
    return (
      <div className="cf-popover-content">
        <Button
          className="cf-item-button"
          onClick={() => {
            logout();
          }}
        >
          Cerrar sesión
        </Button>
      </div>
    );
  };

  const avatar = () => {
    return (
      <Avatar
        className="cf-avatar"
        src={
          sasToken && user?.photoUrl !== "" ? (
            `${user?.photoUrl}?${sasToken}`
          ) : (
            <UserOutlined />
          )
        }
      />
    );
  };

  return (
    user && (
      <Header className="config-navbar-header">
        <div className="cf-navbar-content">
          <div className="cf-title for-web">
            <h2>Mis talleres</h2>
          </div>

          <span className="cf-span-left for-mob" />

          <img className="cf-logo for-mob" src={Logo} alt="CarKer logo" />

          <div className="cf-avatar-container">
            <Popover
              zIndex={2000}
              trigger="click"
              placement="bottomRight"
              content={content}
              overlayInnerStyle={{ borderRadius: "10px", marginTop: "-20px" }}
            >
              <Button className="cf-avatar-button" icon={avatar()}>
                <p className="for-web">{`${user.name} ${user.lastName}`}</p>
                <CaretDownOutlined className="cf-arrow for-web" />
              </Button>
            </Popover>
          </div>
        </div>
        <div className="cf-title for-mob">
          <h2>Mis talleres</h2>
        </div>
      </Header>
    )
  );
};

export const ConfigNavbar = React.memo(MemoizedConfigNavbar);
